import store from '@/store'
import { SESSION_CHECK_AUTH } from '@/store/actions.type'
import Vue from 'vue'
import Router from 'vue-router'
import defaultRoutes from './default'
// Session components
const Login = () => import('@/views/session/login')
const ResetPasswordConfirm = () => import('@/views/session/password-reset')
const ConfirmEmail = () => import('@/views/session/email-confirm')
const ForgotPassword = () => import('@/views/session/forgot')
const ResetPassword = () => import('@/views/session/reset')
const DownloadApp = () => import('@/views/download/download')

// Errors
const Error404 = () => import('@/views/errors/error-404')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    defaultRoutes,
    {
      path: '/session/login',
      component: Login,
      meta: {
        title: 'message.login'
      }
    },
    {
      path: '/session/forgot-password',
      component: ForgotPassword
    },
    {
      path: '/password-confirm',
      component: ResetPasswordConfirm
    },
    {
      path: '/reset-password/:token',
      component: ResetPassword
    },
    {
      path: '/confirm-email/:token',
      component: ConfirmEmail
    },
    {
      path: '/download-app/',
      component: DownloadApp
    },
    {
      path: '/error/404',
      component: Error404,
      name: 'error',
      meta: {
        title: 'Error'
      }
    },
    {
      path: '*',
      redirect: '/error/404'
    }
  ]
})

// Navigation guards before each
router.beforeEach((to, from, next) => {
  if (to.matched.some(element => element.meta.auth)) {
    if (to.path !== '/my-profile') {
      const paths = to.path.split('/')
      let path = ''
      if (paths.includes('places')) {
        path = 'places'
      } else {
        path = paths.length > 2 ? paths[1] + '/' + paths[2] : paths[1]
      }

      if (
        store.getters.getSessionUser.roles &&
        !store.getters.getSessionUser.roles.some(r => r.permissions.some(p => p.module.slug === path && p.slug === to.meta.type))
      ) {
        router.push('/error/404')
      }
    }
    Promise
      .all([store.dispatch(SESSION_CHECK_AUTH)])
      .then(el => {
        if (!store.getters.getIsAuthenticated) {
          router.push('/session/login')
            .catch(err => {
              err = ''
              router.go('/session/login')
            })
        }
        next()
      })
  } else {
    next()
  }
})

export default router;
